@font-face{
    font-family: 'Inter'; /*Nunito*/
    src: url('./assets/fonts/Inter-Regular.ttf')
}
:root{
    /*#48a7ff;*/
    --main-color: #6c63ff;
    --secondary-color: #445b78;
    --body-color: #FBFBFD;
}
*{
    outline: none;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /*font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;*/
    font-family: Inter,"Inter UI",Helvetica,Arial,sans-serif;
}
html{
    text-rendering: optimizelegibility;
    scroll-behavior: smooth;
}
body{
    background-color: var(--body-color);
}
strong{
    color: var(--main-color)
}
p, h1, h2, h3, h4, h5, h6{
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
}
p{
    color: var(--secondary-color);
    letter-spacing: -.02em;
}
h1,h2,h3,h4,h5,h6{
    letter-spacing: -.04em;
}
@media (max-width: 469px){
    html{ font-size: 12px;}
}
@media (min-width: 470px) and (max-width: 640px){
    html{ font-size: 14px;}
}
@media (min-width: 1100px) and (max-width: 2000px){
    html{ font-size: 16px; }
}
.glass{
    border-top: 1px solid rgba(223, 223, 223, 0.7);
    border-left: 1px solid rgba(223, 223, 223, 0.7);
    border-radius: 10px;
    background:rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(15px);
    box-shadow: 0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%);
}