#loaderWrapper{
    width: 100%;
    height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;
}
#loader{
    position: relative;
    width: 200px;
    height: 200px;
}
#loader::before{
    content:'';
    position: absolute;
    top: 85px;
    left:15%;
    width: 70%;
    height: 70%;
    background: #ff3b8d;
    box-shadow: -60px -55px 0 #07b2ff;
    animation: loader 5s linear infinite;
}
#loader::after{
    content:'';
    position: absolute;
    bottom: 85px;
    left:15%;
    width: 70%;
    height: 70%;
    background: #8dff08;
    box-shadow: 60px 55px 0 #ffeb3b;
    border-radius: 50%;
    animation: loader 5s linear infinite;
}
#loader span{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-weight: 500;
    font-size: 1.1em;
    color: rgba(0,0,0,.1)
}
#loader span::before{
    content: '';
    position: absolute;
    top:0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: transparent;
    border-radius: 50%;
    box-shadow: inset 0 0 20px rgba(0,0,0,.08);
}
@keyframes loader{
    0%{
        filter: blur(50px) hue-rotate(0deg)
    }
    100%{
        filter: blur(50px) hue-rotate(360deg);
    }
}